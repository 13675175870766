<template>
  <b-container>
    <b-form v-on:submit.prevent>
      <b-row>
        <b-col cols="8">
          <b-card>
            <label for="items" class="text-primary font-weight-bolder mb-1"
              >Detail</label
            >
            <b-row>
              <b-col>
                <b-form-group label="Kode Promosi" label-for="code">
                  <b-form-input
                    id="code"
                    placeholder="Code"
                    v-model="form.code"
                    required
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6">
                <b-form-group label="Tanggal Mulai" label-for="start-at">
                  <flat-pickr v-model="form.startAt" class="form-control" />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Tanggal Berakhir" label-for="end-at">
                  <flat-pickr v-model="form.endAt" class="form-control" />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group label="Alamat URL" label-for="url">
                  <b-form-input
                    id="url"
                    placeholder="Alamat URL"
                    v-model="form.url"
                    required
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6" class="mt-1">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  class="mr-1"
                  @click="updatePromotion()"
                  block
                >
                  Simpan
                </b-button>
              </b-col>
              <b-col md="6" class="mt-1">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="danger"
                  class="mr-1"
                  @click="deletePromotion()"
                  block
                >
                  Hapus
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col cols="4">
          <b-card>
            <b-form-group
              :state="errors"
              label="Image"
              label-for="Image"
              invalid-feedback="Image is required"
            >
              <validation-provider
                #default="{ errors }"
                name="Photo"
                rules="required"
              >
                <b-img
                  id="photoURL"
                  :src="photo"
                  fluid
                  alt="product photo"
                  class="mb-1"
                  v-if="(hasImage === '') | null"
                />
                <image-uploader
                  :preview="true"
                  :className="['fileinput', { 'fileinput--loaded': hasImage }]"
                  :debug="0"
                  :autoRotate="true"
                  accept="image/*"
                  doNotResize="['gif', 'svg']"
                  outputFormat="string"
                  @input="setImage"
                ></image-uploader>
                <small class="text-danger">{{
                  errors[0] || errMessageImage
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-card>
        </b-col>
      </b-row>
    </b-form>
  </b-container>
</template>

<script>
import {
  BImg,
  BFormFile,
  BFormSelect,
  BListGroupItem,
  BAvatar,
  BCard,
  BCardText,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BContainer,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import Prism from "vue-prism-component";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ImageUploader from "vue-image-upload-resize";
import { $themeConfig } from "@themeConfig";
import Cleave from "vue-cleave-component";
import "quill/dist/quill.snow.css";
import { quillEditor } from "vue-quill-editor";
import flatPickr from "vue-flatpickr-component";
import moment from "moment";

export default {
  components: {
    quillEditor,
    ToastificationContent,
    ImageUploader,
    Cleave,
    ValidationProvider,
    ValidationObserver,
    BImg,
    BFormFile,
    BFormSelect,
    BListGroupItem,
    BAvatar,
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BContainer,
    Prism,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      form: {
        url: "",
        startAt: null,
        endAt: null,
        url: "",
      },
      permissions: [],
      hasImage: "",
      image: "",
      photo: "",
      errors: "",
      errMessage: "",
      errMessageImage: "",
    };
  },
  setup() {
    // App Name
    const { baseUrl, apiUrl } = $themeConfig.app;
    return {
      baseUrl,
      apiUrl,
    };
  },
  created() {
    this.getDetails();
  },
  methods: {
    moment(date) {
      return moment(date, "DD-MM-YYYY").format("YYYY-MM-DD");
    },
    getDetails() {
      const id = this.$route.params.id;
      this.$http.get("promotion/" + id).then((response) => {
        console.log(response.data.data);
        this.form.url = response.data.data.url;
        const { baseUrl } = $themeConfig.app;
        this.photo = baseUrl;
        this.photo += "storage/promotion/";
        this.photo += response.data.data.image;
        this.image = this.getBase64Image(document.getElementById("photoURL"));
        this.form.startAt = this.moment(response.data.data.start_at);
        this.form.endAt = this.moment(response.data.data.end_at);
        this.form.code = response.data.data.code;
      });
    },
    getBase64Image(img) {
      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);
      this.image = canvas.toDataURL("image/png");
      this.image += this.image.replace(/^data:image\/(png|jpg);base64,/, "");
      // console.log(this.image)
    },
    setImage: function (file) {
      this.hasImage = true;
      this.image = file;
    },
    updatePromotion() {
      const id = this.$route.params.id;
      this.$http
        .post("promotion/" + id + "/update", {
          url: this.form.url,
          image: this.image,
        })
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notifikasi",
              icon: "BellIcon",
              text: "Promosi berhasil diperbaharui",
              variant: "success",
            },
          });
          location.href = "/promotions";
          // console.log(response.data.data)
        })
        .catch((errors) => {
          this.errMessage = errors.response.data.message;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notifikasi",
              icon: "BellIcon",
              text: this.errMessage,
              variant: "warning",
            },
          });
          console.log(errors.response);
        });
    },
    deletePromotion() {
      const id = this.$route.params.id;
      this.$http
        .post("promotion/" + id + "/delete")
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notifikasi",
              icon: "BellIcon",
              text: "Promosi berhasil dihapus",
              variant: "danger",
            },
          });
          location.href = "/promotions";
          // console.log(response.data.data)
        })
        .catch((errors) => {
          this.errMessage = errors.response.data.message;
          console.log(errors.response);
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<style>
#category option:disabled {
  font-weight: bolder;
  /* color: darkgray; */
  /* text-align: center; */
  background-color: #ebebeb;
}
#category option:not([disabled]) {
  margin-left: 40px !important;
  transform: scaleX(200px);
}
img.img-preview {
  width: 100%;
  margin-bottom: 15px;
}
</style>